import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/Layout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import SearchNav from "../../components/search/SearchNav"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"


const Methods = ({ data, pageContext }) => (

<Layout>
<Container>

  {isLoggedIn() ? (
  <>
 

   <div className="">
   <Type type="h2">Methods</Type>
     <div className="grid grid-cols-2 lg:grid-cols-2 gap-4">
         {data.allAirtable.edges.map((edge) => (
          <div className="bg-green/10 p-8 rounded-lg">
            <Type type="h3">{edge.node.data.Name}</Type>
            <Type type="p6">{edge.node.data.MCatDescription}</Type>
            <div className="grid grid-cols-2 gap-8">
            {edge.node.data.Methods.map((MethodsArray) => (
              <div className="bg-green text-white p-4 rounded-lg">
              <Type type="h6">{MethodsArray.data.Name}</Type>
              <Type type="p6">{MethodsArray.data.Introduction}</Type>
              </div>
              ))}
            </div>
          </div>
  
        ))}
     </div>
   </div>

 
  </>
) : (
  <>
    <LoginPrompt/>
  </>
)}




</Container>
</Layout>


)

export const Head = () => <title>Methods</title>

export default Methods

// query airtable for the Title and Path of each record,
// filtering for only records in the Sections table.
// sort: {fields: data___Order, order: ASC}
    
export const query = graphql` 
query ($skip: Int!, $limit: Int!) {
  allAirtable (
    filter: {queryName: {eq: "MethodCategories"}}
    skip: $skip
    limit: $limit
  ) {
    edges {
      node {
        data {
          Name
          Path
          MCatDescription
          Methods {data {
            Name
            Introduction
          }}
        }
      }
    }
  }
}
`
